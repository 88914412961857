const OPTION = [
  {
    name: '아이디 찾기',
    path: '/find-id',
  },
  {
    name: '비밀번호 찾기',
    path: '/find-password',
  },
  {
    name: '회원가입',
    path: '/signup',
  },
];

export default OPTION;
