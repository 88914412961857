const PROGRESS_TITLE = [
  {
    step: 0,
    title: '가게 등록',
  },
  {
    step: 1,
    title: '가게 카테고리 설정',
  },
  {
    step: 2,
    title: '메인 정보 입력',
  },
  {
    step: 3,
    title: '세부 정보 입력',
  },
  {
    step: 4,
    title: '가게 정보 확인',
  },
];

export default PROGRESS_TITLE;
